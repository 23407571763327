import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "owl.carousel/dist/assets/owl.theme.green.css"; // Import any additional theme if needed

const HomeSlider = () => {
  return (
    <>
    <div className="mt-80">
       <OwlCarousel
          className="owl-theme"
          loop
          margin={10}
          autoplay={true}
          autoplayTimeout={2000}
          smartSpeed={450}
          nav={false} 
          dots={false} 
          responsive={{
            0: {
              items: 1,
            },
            600: {
              items: 1,
            },
            1000: {
              items: 1,
          
            },
          }}
        >
          <div class="item">
            <picture>
              <source
                media="(min-width:768px)"
                srcSet="assets/images/Banner1.webp"
                className="w-100 vh-75"
              />
              <source
                media="(max-width:768px)"
                className="w-100"
                srcSet="assets/images/Banner1.webp"
                height={486}
                width={320}
              />
              <img
                src="assets/images/Banner1.webp"
                className="vh-75 w-100"
                alt=""
                title=""
              />
            </picture>
          </div>
          <div class="item">
            <picture>
              <source
                media="(min-width:768px)"
                srcSet="assets/images/Banner2.webp"
                className="w-100"
              />
              <source
                media="(max-width:768px)"
                className="w-100"
                srcSet="assets/images/Banner2.webp"
                height={486}
                width={320}
              />
              <img
                src="assets/images/Banner2.webp"
                className="img-fluid w-100"
                alt=""
                title=""
              />
            </picture>
          </div>
          <div class="item">
            <picture>
              <source
                media="(min-width:768px)"
                srcSet="assets/images/Banner3.webp"
                className="w-100"
              />
              <source
                media="(max-width:768px)"
                className="w-100"
                srcSet="assets/images/Banner3.webp"
                height={486}
                width={320}
              />
              <img
                src="assets/images/Banner3.webp"
                className="img-fluid w-100"
                alt=""
                title=""
              />
            </picture>
           
          </div>
        </OwlCarousel>

        <div class="carousel-content d-md-block d-none">
            <h3>Step into the K-pop Style Realm</h3>
            <h5>Your Ultimate Destination for Trendsetting Fashion</h5>
            <a href="#" class="button">Shop now</a>
        </div>
        </div>
        </>
  );
};

export default HomeSlider;
