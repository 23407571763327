import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import HomeSlider from "../components/HomeSlider";
import Gallery from "../components/Gallery";

import BrandSlider from "../components/BrandSlider";
import ShopBy from "../components/ShopBy";
const Home = () => {
  document.title = "Winter Bear";
  document.getElementsByTagName("META")[2].content = "Winter Bear";
  return (
    <>
      <Header />
      <>
        {/* Content Section */}
        <HomeSlider />

       
        <BrandSlider customClass="section body-bg" containerClass="container-fluid " />

        <section className="body-bg section">
          <div className="container">
            <div className="heading  "> Our Collection </div>
            <div className="row">
              <div className="col-md-3 mb-4">
                <div className="collection-box">
                  <img src="assets/images/collection.png" />
                  <h4>Stationery</h4>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="collection-box">
                  <img src="assets/images/collection1.png" />
                  <h4>Figurines</h4>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="collection-box">
                  <img src="assets/images/collection2.png" />
                  <h4>Manga Books</h4>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="collection-box">
                  <img src="assets/images/collection3.png" />
                  <h4>Living Accessories</h4>
                </div>
              </div>
              <div className="col-md-3 mb-md-0 mb-4">
                <div className="collection-box">
                  <img src="assets/images/collection4.png" />
                  <h4>Home Soft</h4>
                </div>
              </div>
              <div className="col-md-3 mb-md-0 mb-4">
                <div className="collection-box">
                  <img src="assets/images/collection5.png" />
                  <h4>Apparels</h4>
                </div>
              </div>
              <div className="col-md-3 mb-md-0 mb-4">
                <div className="collection-box">
                  <img src="assets/images/collection6.png" />
                  <h4>Consumer Electronics</h4>
                </div>
              </div>
              <div className="col-md-3 mb-md-0 mb-4">
                <div className="collection-box">
                  <img src="assets/images/collection7.png" />
                  <h4>Fashion Accessories</h4>
                </div>
              </div>
            </div>
          </div>
        </section>
       <ShopBy />
        <Gallery />
      </>

      <Footer />
    </>
  );
};

export default Home;
