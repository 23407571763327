import React from 'react';

import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Stores from './pages/Stores';
import Faqs from './pages/Faqs';
import ShopAll from './pages/Shopall';
import Account from './pages/Account';
import MyOrder from './pages/MyOrder';
import Address from './pages/Address';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import EventsSection from './pages/Events';

const App = () => {
  return (
    <div>

      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/stores" element={<Stores />} />
          <Route path="/shop" element={<ShopAll />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/accounts" element={<Account />} />
          <Route path="/order" element={<MyOrder />} />
          <Route path="/address" element={<Address />} />
          <Route path="/events" element={<EventsSection />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
    
    </div>
  );
};

export default App;
