import React from "react";
import Newsletter from "./Newsletter";
const Footer = () => {
  const backgroundImageUrl = "../assets/image/bg-top.png";

  const containerStyle = {
    background: `url(${backgroundImageUrl}) cover repeat `,
  };
  return (
    <>
      <Newsletter />
      <div className="top-footer"></div>
      <footer className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="footer-heading">
                <h4>Quick links</h4>
                <div className="row">
                  <div className="col-md-6 col-6">
                    <ul>
                      <li>
                        <a href="#"> Home</a>
                      </li>
                      <li>
                        <a href="#">shop</a>
                      </li>
                      <li>
                        <a href="#">bearclub</a>
                      </li>
                      <li>
                        <a href="#">About</a>
                      </li>
                      <li>
                        <a href="#">faq</a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-6">
                    <ul>
                      <li>
                        <a href="#">my account</a>
                      </li>
                      <li>
                        <a href="#">wishlist</a>
                      </li>
                      <li>
                        <a href="#">our stores</a>
                      </li>
                      <li>
                        <a href="#">events</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="footer-heading">
                <h4>Important links</h4>
                <ul>
                  <li>
                    <a href="#">privacy policy</a>
                  </li>
                  <li>
                    <a href="#">shipping policy</a>
                  </li>
                  <li>
                    <a href="#">terms &amp; conditions</a>
                  </li>
                  <li>
                    <a href="#">returns &amp; refunds</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="footer-heading">
                <h4>Shop by</h4>
                <div className="row">
                  <div className="col-md-6 col-6">
                    <ul>
                      <li>
                        <a href="#">BT21</a>
                      </li>
                      <li>
                        <a href="#">line friends</a>
                      </li>
                      <li>
                        <a href="#">the smurfs</a>
                      </li>
                      <li>
                        <a href="#">viz media</a>
                      </li>
                      <li>
                        <a href="#">naruto</a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-6">
                    <ul>
                      <li>
                        <a href="#">banpresto</a>
                      </li>
                      <li>
                        <a href="#">Disney &amp; marvel</a>
                      </li>
                      <li>
                        <a href="#">k-pop albums</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 text-center text-white margin-bottom-120">
              <img src="assets/images/footer-logo.png" className="img-fluid" />
              <p className="">
                Official BT21 &amp; LINE FRIENDS Merchandise in India
              </p>
            </div>
          </div>
        </div>
      </footer>

      <div className="copy-rights position-relative">
        <div className="container">
          <div className="text-center">
            <img
              src="assets/images/footer-img.png"
              className="img-fluid mx-auto d-block margin-top--180"
            />
            <p className="mb-0 py-2">
              <b>© 2023 Digital Partners IM Solutions</b>
            </p>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="loginModal"
        tabindex="-1"
        aria-labelledby="loginModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header border-0 p-2">
              <button
                type="button"
                class="btn-close text-end"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <h1
                class="modal-title fs-3 mx-auto text-center d-block fs-5 fw-bold ps-3 mb-1"
                id="loginModalLabel"
              >
                Login
              </h1>

              <p className="text-center d-block">
                Choose one of the option to go
              </p>
              <form className="px-3 py-3">
                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control bg-white"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Email address"
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    className="form-control  bg-white"
                    id="exampleInputPassword1"
                    placeholder="Password"
                  />
                </div>

                <div className="mb-3 form-check">
                  <p className="text-center">Or continue with</p>
                  <img
                    src="assets/images/google.png"
                    className="d-block mx-auto"
                  />
                </div>
                <div className="mb-3 form-check text-center">
                  <button
                    type="submit"
                    className="btn btn-primary rounded-pill"
                  >
                    {" "}
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Log in &nbsp; &nbsp;
                    &nbsp; &nbsp;
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="RegisterModal"
        tabindex="-1"
        aria-labelledby="RegisterModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
          <div class="modal-header border-0 p-2">
              <button
                type="button"
                class="btn-close text-end"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <h1
                class="modal-title fs-3 mx-auto text-center d-block fs-5 fw-bold ps-3 mb-1"
                id="loginModalLabel"
              >
                Login
              </h1>

              <p className="text-center d-block">
                Choose one of the option to go
              </p>
              <form className="px-3 py-3">
                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control bg-white"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Email address"
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    className="form-control  bg-white"
                    id="exampleInputPassword1"
                    placeholder="Password"
                  />
                </div>

                <div className="mb-3 form-check">
                  <p className="text-center">Or continue with</p>
                  <img
                    src="assets/images/google.png"
                    className="d-block mx-auto"
                  />
                </div>
                <div className="mb-3 form-check text-center">
                  <button
                    type="submit"
                    className="btn btn-primary rounded-pill"
                  >
                    {" "}
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Log in &nbsp; &nbsp;
                    &nbsp; &nbsp;
                  </button>
                </div>
              </form>
            </div>
            
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="RegisterdModal"
        tabindex="-1"
        aria-labelledby="RegisterdModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header border-0">
             
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
          
            <img src="assets/images/register.png" className="img-fluid d-block mx-auto" />
            <p className="text-center fw-semibold fs-3">Account created successfully!
            </p>
            <p className="text-center fw-semibold fs-6 text-secondary">
            Welcome aboard! </p>
            </div>
           
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
