import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "owl.carousel/dist/assets/owl.theme.green.css";

const BrandSlider = ({ customClass, containerClass }) => {
  return (
    <section className={`${customClass}`}>
      <div className={` ${containerClass}`}>
        <OwlCarousel
          className="owl-theme"
          loop={true}
          autoplay={true}
          autoplayTimeout={2000}
          dots={false}
          smartSpeed={450}
          margin={10}
          nav={false}
          responsive={{
            0: {
              items: 1,
            },
            600: {
              items: 1,
            },
            1000: {
              items: 4,
            },
            1600: {
              items: 7,
            },
          }}
        >
          <div className="item">
            <img
              src="assets/images/disney.png"
              className="w-auto d-block mx-auto"
            />
          </div>
          <div className="item">
            <img
              src="assets/images/line.png"
              className="w-auto d-block mx-auto"
            />
          </div>
          <div className="item">
            <img
              src="assets/images/viz.png"
              className="w-auto d-block mx-auto"
            />
          </div>
          <div className="item">
            <img
              src="assets/images/smurfs.png"
              className="w-auto d-block mx-auto"
            />
          </div>
          <div className="item">
            <img
              src="assets/images/bt21.png"
              className="w-auto d-block mx-auto"
            />
          </div>
        </OwlCarousel>
      </div>
    </section>
  );
};

export default BrandSlider;
